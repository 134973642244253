import React, { useState } from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"
import Section from "../../components/section"
import Seo from "../../components/seo"
import BacktapVideo from "../../assets/backtap.mp4"
import SiriVideo from "../../assets/siri.mp4"
import AlsoHelpsMenu from "../../components/alsoHelpsMenu"
import { playVideo, bridgeAction, playVideoTitle } from "../../components/utils"

const Page = ({ data }) => {
  const [playingBacktap, setPlayingBacktap] = useState(false)
  const [playingSiri, setPlayingSiri] = useState(false)

  return (
    <Layout containerId="home">
      <Seo title="Mente" />

      <div className="feature-sections">
        <section className="full-width">
          <div className="grid full-width-grid">
            <div className="full-width-grid" style={{ textAlign: "center" }}>
              <h2 className="feature-group-title">Husk alt. Raskt.</h2>
              <p className="feature-group-decription inapp-invisible">
                Mente tar trygt vare på oppgaver, ideer og alt annet du vil
                huske.
              </p>
              <p className="feature-group-decription inapp-only">
                Prøv de mange måtene å få ting i Mente på:
              </p>
            </div>
          </div>
        </section>

        <div className="inapp-only">
          <section className="full-width">
            <div className="grid feature-section" style={{ marginTop: 0 }}>
              <div>
                <ul className="feature-list">
                  <Feature
                    image={data.iconPhone.childImageSharp.fixed}
                    alt="Plussikon"
                    title="Trykk på baksiden av telefonen"
                    description="Fungerer fra alle apper, til og med når telefonen er låst!"
                  >
                    <div style={{ textAlign: "right" }}>
                      <button
                        className="link-button"
                        onClick={() => {
                          bridgeAction("capture-phone")
                        }}
                      >
                        Konfigurer back-tap
                      </button>
                    </div>
                  </Feature>
                  <Feature
                    image={data.iconSiri.childImageSharp.fixed}
                    alt="Plussikon"
                    title="Hei, Siri!"
                    description="Perfekt i bilen eller når du er på farten"
                  >
                    <div style={{ textAlign: "right" }}>
                      <button
                        className="link-button"
                        onClick={() => {
                          bridgeAction("capture-siri")
                        }}
                      >
                        Konfigurer Siri
                      </button>
                    </div>
                  </Feature>
                  <Feature
                    image={data.iconCommand.childImageSharp.fixed}
                    alt="Plussikon"
                    title="På Mac"
                    description="Bruk en global snarvei på tastaturet, uansett hvilken app du står i"
                  >
                    <div style={{ textAlign: "right" }}>
                      <button
                        className="link-button"
                        onClick={() => {
                          bridgeAction("capture-mac")
                        }}
                      >
                        Konfigurer snarvei
                      </button>
                    </div>
                  </Feature>
                </ul>
              </div>
              <div>
                <ul className="feature-list">
                  <Feature
                    image={data.iconPlus.childImageSharp.fixed}
                    alt="Plussikon"
                    title="Hold inne + knappen"
                    description="... og legg til mange oppgaver samtidig."
                  />
                  <Feature
                    image={data.iconShare.childImageSharp.fixed}
                    alt="Del"
                    title="Send lenker fra Safari og andre apper"
                    description="Trykk på delingsikonet. Velg Mente. Voilà."
                  />
                  <Feature
                    image={data.iconCrosshair.childImageSharp.fixed}
                    alt="Sikte"
                    title="Dra + knappen dit du vil"
                    description="... og sett inn en oppgave på riktig sted i listen."
                  />
                </ul>
              </div>
            </div>
          </section>
        </div>

        <div className="inapp-invisible" style={{ marginTop: "100px" }}>
          <Section
            id="feature-backtap"
            title="Skriv det"
            description="To tap på baksiden av telefonen, eller en tastatursnarvei på Mac, og du kan notere ting til Mente. Raskere blir det ikke!"
            video={BacktapVideo}
            videoEvent={setPlayingBacktap}
            sectionStyle={{ marginTop: "0" }}
          >
            <div id="feature-backtap-cta">
              <button
                className="btn-cta"
                onClick={() => playVideo("feature-backtap", "feature-backtap")}
                style={{ marginBottom: "0.5em" }}
              >
                {playVideoTitle(playingBacktap, "1. Se hvordan")}
              </button>
              <br />
              <button
                className="btn-cta"
                onClick={() => bridgeAction("capture-phone")}
                style={{ marginBottom: "0.5em" }}
              >
                2. Prøv det selv på iPhone
              </button>
              <br />
              <button
                className="btn-cta"
                onClick={() => bridgeAction("capture-mac")}
              >
                3. Prøv det selv på Mac
              </button>
            </div>
          </Section>

          <Section
            id="feature-siri"
            title="Si det"
            description="&laquo;Hei Siri, ha i Mente...!&raquo;. Perfekt i bilen og når du er på farten."
            video={SiriVideo}
            videoEvent={setPlayingSiri}
          >
            <button
              className="btn-cta"
              onClick={() => playVideo("feature-siri", "feature-siri")}
              style={{ marginBottom: "0.5em" }}
            >
              {playVideoTitle(playingSiri, "1. Se hvordan")}
            </button>
            <br />
            <button
              className="btn-cta"
              onClick={() => bridgeAction("capture-siri")}
            >
              2. Prøv det på iOS
            </button>
          </Section>

          <section className="full-width" style={{ marginTop: "150px" }}>
            <div className="grid full-width-grid">
              <div className="full-width-grid" style={{ textAlign: "center" }}>
                <h2 className="feature-group-title">Flere triks</h2>
              </div>
            </div>
            <div className="grid feature-section" style={{ marginTop: 0 }}>
              <div>
                <ul className="feature-list">
                  <Feature
                    image={data.iconPlus.childImageSharp.fixed}
                    alt="Plussikon"
                    title="Hold inne + knappen"
                    description="... og legg til mange oppgaver samtidig."
                  />
                  <Feature
                    image={data.iconShare.childImageSharp.fixed}
                    alt="Del"
                    title="Send lenker fra Safari og andre apper"
                    description="Trykk på delingsikonet. Velg Mente. Voilà."
                  />
                </ul>
              </div>
              <div>
                <ul className="feature-list">
                  <Feature
                    image={data.iconCrosshair.childImageSharp.fixed}
                    alt="Sikte"
                    title="Dra + knappen dit du vil"
                    description="... og sett inn en oppgave på riktig sted i listen."
                  />
                </ul>
              </div>
            </div>
          </section>
        </div>

        <AlsoHelpsMenu except="capture" />
      </div>
    </Layout>
  )
}

const Feature = ({ image, alt, title, description, children }) => (
  <li className="feature-wrapper">
    <Img fixed={image} alt={alt} className="feature-icon" />
    <div className="feature-title">{title}</div>
    <p className="feature-description">{description}</p>
    {children}
  </li>
)

const WrappedPage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        iconPlus: file(relativePath: { eq: "icon-plus.png" }) {
          childImageSharp {
            fixed(width: 44) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        iconCrosshair: file(relativePath: { eq: "icon-crosshair.png" }) {
          childImageSharp {
            fixed(width: 44) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        iconShare: file(relativePath: { eq: "icon-share.png" }) {
          childImageSharp {
            fixed(width: 44) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        iconCommand: file(relativePath: { eq: "icon-command.png" }) {
          childImageSharp {
            fixed(width: 44) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        iconSiri: file(relativePath: { eq: "icon-siri.png" }) {
          childImageSharp {
            fixed(width: 44) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        iconPhone: file(relativePath: { eq: "icon-phone.png" }) {
          childImageSharp {
            fixed(width: 44) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => <Page data={data} {...props} />}
  />
)

export default WrappedPage;
