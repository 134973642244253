import React from "react"
import VideoWrapper from "./videoWrapper"

const Section = ({
  id,
  title,
  description,
  video,
  videoEvent,
  sectionStyle,
  children,
}) => (
  <section
    className="grid feature-section alternating full-width"
    id={id}
    style={sectionStyle}
  >
    <div>
      <h2 className="feature-group-title">{title}</h2>
      <p className="feature-group-decription">{description}</p>
      <ul className="feature-list">{children}</ul>
    </div>
    <div>
      <VideoWrapper id={`video_${id}`} video={video} videoEvent={videoEvent} />
    </div>
  </section>
)

export default Section
